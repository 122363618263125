// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import { wrapWithConsumption } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

export type ModernizationLinkProp = {
  loading: boolean
};

type Action = {
  type: string,
  payload: any
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: ModernizationLinkProp = {
  loading: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('pages/overview/softwareAssets');
const GET_MODERNIZATION_PDF = wrapWithNamespace('GET_MODERNIZATION_PDF');
const GET_MODERNIZATION_PDF_SUCCESS = wrapWithNamespace('GET_MODERNIZATION_PDF_SUCCESS');
const TOGGLE_LOADING = wrapWithNamespace('TOGGLE_LOADING');

export const getModernizationPDF = createAction(GET_MODERNIZATION_PDF);
export const getModernizationPDFSuccess = createAction(GET_MODERNIZATION_PDF_SUCCESS);
export const toggleLoading = createAction(TOGGLE_LOADING);

/***************
 *   REDUCER   *
 ***************/

export default (state: ModernizationLinkProp = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_MODERNIZATION_PDF_SUCCESS]: mergeState,
    [TOGGLE_LOADING]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};

// @flow

import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import { wrapWithConsumption } from 'src/redux/utils';
import {
  PATHS,
  USERS_SECTIONS,
  HOME_LIST,
  HIGH_COST_LIST,
  DEPT_SPEND_LIST,
  ONEDRIVE_STORAGE_LIST,
  COMPANY_ONEDRIVE_STORAGE_LIST
} from 'src/constants';
import { SEARCH_KEYS } from './searchBar.config';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

export type SearchState = {
  HOME_LIST: Array<Object>,
  HIGH_COST_LIST: Array<Object>,
  DEPT_SPEND_LIST: Array<Object>,
  ONEDRIVE_STORAGE_LIST: Array<Object>,
  COMPANY_ONEDRIVE_STORAGE_LIST: Array<Object>,
  SEARCH_TERM: string
};

type Action = {
  type: string,
  payload: any
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: SearchState = {
  HOME_LIST: [],
  HIGH_COST_LIST: [],
  DEPT_SPEND_LIST: [],
  ONEDRIVE_STORAGE_LIST: [],
  COMPANY_ONEDRIVE_STORAGE_LIST: [],
  SEARCH_TERM: ''
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithListNamespace = wrapWithConsumption('search/lists');
const UPDATE_USER_HOME_LIST = wrapWithListNamespace('UPDATE_USER_HOME_LIST');
const UPDATE_USER_HIGH_COST_LIST = wrapWithListNamespace('UPDATE_USER_HIGH_COST_LIST');
const UPDATE_USER_DEPT_SPEND_LIST = wrapWithListNamespace('UPDATE_USER_DEPT_SPEND_LIST');
const UPDATE_ONEDRIVE_STORAGE_LIST = wrapWithListNamespace('UPDATE_ONEDRIVE_STORAGE_LIST');
const UPDATE_COMPANY_ONEDRIVE_STORAGE_LIST = wrapWithListNamespace('UPDATE_COMPANY_ONEDRIVE_STORAGE_LIST');
const RESET_USER_LISTS = wrapWithListNamespace('RESET_USER_LISTS');
const GET_INITIAL_USER_LIST = wrapWithListNamespace('GET_INITIAL_USER_LIST');

const wrapWithTermNamespace = wrapWithConsumption('search/terms');
const UPDATE_USER_SEARCH_TERM = wrapWithTermNamespace('UPDATE_USER_SEARCH_TERM');
const CLEAR_SEARCH_KEY = wrapWithTermNamespace('CLEAR_SEARCH_KEY');

export const updateUserHomeList = createAction(UPDATE_USER_HOME_LIST);
export const updateUserHighCostList = createAction(UPDATE_USER_HIGH_COST_LIST);
export const updateUserDeptSpendList = createAction(UPDATE_USER_DEPT_SPEND_LIST);
export const updateOneDriveStorageList = createAction(UPDATE_ONEDRIVE_STORAGE_LIST);
export const updateCompanyOneDriveList = createAction(UPDATE_COMPANY_ONEDRIVE_STORAGE_LIST);
export const resetUserLists = createAction(RESET_USER_LISTS);
export const getInitialDataSuccess = createAction(GET_INITIAL_USER_LIST);

export const updateUserSearchTerm = createAction(UPDATE_USER_SEARCH_TERM);
export const clearSearchKey = createAction(CLEAR_SEARCH_KEY);

// $FlowIgnore
export const searchTypes = R.cond([
  [R.equals(PATHS.USERS), R.always({ keys: SEARCH_KEYS.ALL_SEARCH_KEY, action: updateUserHomeList, term: HOME_LIST })],
  [
    R.equals(PATHS.HIGH_COST_USERS),
    R.always({
      keys: SEARCH_KEYS.ALL_SEARCH_KEY,
      action: updateUserHighCostList,
      term: HIGH_COST_LIST
    })
  ],
  [
    R.equals(PATHS.DEPARTMENT_SPEND),
    R.always({
      keys: SEARCH_KEYS.NAME_SEARCH_KEY,
      action: updateUserDeptSpendList,
      term: DEPT_SPEND_LIST
    })
  ],
  [
    R.equals(PATHS.ONEDRIVE_TOP_USERS),
    R.always({
      keys: R.concat(SEARCH_KEYS.NAME_SEARCH_KEY, SEARCH_KEYS.DEPARTMENT_SEARCH_KEY),
      action: updateOneDriveStorageList,
      term: ONEDRIVE_STORAGE_LIST
    })
  ],
  [
    R.equals(USERS_SECTIONS.COMPANY_ONEDRIVE_STORAGE),
    R.always({
      keys: SEARCH_KEYS.NAME_SEARCH_KEY,
      action: updateCompanyOneDriveList,
      term: COMPANY_ONEDRIVE_STORAGE_LIST
    })
  ],
  [R.T, R.always({ keys: SEARCH_KEYS.ALL_SEARCH_KEY, action: updateUserHomeList, term: HOME_LIST })]
]);

/***************
 *   REDUCER   *
 ***************/

export default (state: SearchState = initialState, action: Action) => {
  const { type, payload } = action;
  const reducer = {
    [UPDATE_USER_HOME_LIST]: mergeState,
    [UPDATE_USER_HIGH_COST_LIST]: mergeState,
    [UPDATE_USER_DEPT_SPEND_LIST]: mergeState,
    [UPDATE_ONEDRIVE_STORAGE_LIST]: mergeState,
    [UPDATE_COMPANY_ONEDRIVE_STORAGE_LIST]: mergeState,
    [RESET_USER_LISTS]: mergeState,
    [UPDATE_USER_SEARCH_TERM]: mergeState,
    [GET_INITIAL_USER_LIST]: mergeState,
    [CLEAR_SEARCH_KEY]: () =>
      R.mergeDeepRight(state, {
        SEARCH_TERM: ''
      })
  }[type];

  return reducer ? reducer(state, payload) : state;
};

// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

type UserDataType = {
  email: string,
  name: string,
  userId: string,
  expiryUnix: string,
  roles: [],
  authorization: Object,
  organizations: [],
  lang: string,
  partnerId: string
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: UserDataType = {
  email: '',
  name: '',
  userId: '',
  expiryUnix: '',
  roles: [],
  authorization: {},
  organizations: [],
  lang: '',
  partnerId: ''
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('modules/userData');
const GET_USER_DATA = wrapWithNamespace('GET_USER_DATA');
const GET_USER_DATA_SUCCESS = wrapWithNamespace('GET_USER_DATA_SUCCESS');

export const getUserData = createAction(GET_USER_DATA);
export const getUserDataSuccess = createAction(GET_USER_DATA_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: UserDataType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_USER_DATA_SUCCESS]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};

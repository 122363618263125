// @flow

import { createAction } from 'redux-actions';

import { wrapWithConsumption } from 'src/redux/utils';

/***************
 *   ACTIONS   *
 ***************/
const wrapWithNamespace = wrapWithConsumption('modules/app');
export const RESET_APP = wrapWithNamespace('RESET_APP');

export const resetApp = createAction(RESET_APP);

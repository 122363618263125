import { combineReducers } from 'redux';

import { REDUCER_NAMESPACES } from 'src/constants';
import { RESET_APP } from './modules/app/app.duck';
import { default as currencyOptions } from './modules/currency/currency.duck';
import { default as landing } from 'src/pages/landing/landing.duck';
import { default as msAuth } from './modules/msAuth/msAuth.duck';
import { default as oneDriveDailyStorage } from 'src/pages/oneDriveDailyStorage/oneDriveDailyStorage.duck';
import { default as organization } from './modules/organization/organization.duck';
import { default as overview } from 'src/pages/overview/overview.duck';
import { default as recommendations } from 'src/pages/recommendations/recommendations.duck';
import { default as search } from 'src/components/search/searchBar/searchBar.duck';
import { default as shell } from './modules/shell/shell.duck';
import { default as softwareAssets } from 'src/pages/overview/widgets/softwareAssets/softwareAssets.duck';
import { default as userData } from './modules/userData/userData.duck';
import { default as users } from 'src/pages/users/users.duck';
import { default as report } from './modules/report/report.duck';

const consumptionReducer = (state, action) => {
  // https://alligator.io/redux/reset-state-redux/
  if (action.type === RESET_APP) {
    state = undefined;
  }

  return combineReducers({
    currencyOptions,
    landing,
    msAuth,
    oneDriveDailyStorage,
    organization,
    overview,
    recommendations,
    search,
    shell,
    softwareAssets,
    userData,
    users,
    report
  })(state, action);
};

export const rootReducer = combineReducers({
  [REDUCER_NAMESPACES.OFFICE_365_OPTIMIZER]: consumptionReducer
});

export default consumptionReducer;

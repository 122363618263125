// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { mergeState, setState } = redux;

/*************
 *   TYPES   *
 *************/

/** @see https://github.com/STRML/react-grid-layout#grid-item-props */
export type GridItem = {
  i: string,
  x: number,
  y: number,
  w: number,
  h: number
};

type RawWidgetsState = {
  planHighlights: Object,
  plans: [],
  highCostUsers: [],
  recommendations: Object,
  oneDrive: Object,
  topUsers: [],
  departments: [],
  users: []
};

type OverviewState = {
  layout: GridItem[],
  widgets: RawWidgetsState,
  dataLoaded: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: OverviewState = {
  // Phase 1 GridItem
  layout: [],
  widgets: {
    planHighlights: Object,
    plans: [],
    highCostUsers: [],
    recommendations: Object,
    oneDrive: Object,
    topUsers: [],
    departments: [],
    users: []
  },
  dataLoaded: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('pages/overview');
const SET_WIDGETS_LAYOUT = wrapWithNamespace('SET_WIDGETS_LAYOUT');
const GET_WIDGETS_DATA = wrapWithNamespace('GET_WIDGETS_DATA');
const GET_WIDGETS_DATA_SUCCESS = wrapWithNamespace('GET_WIDGETS_DATA_SUCCESS');

export const setWidgetLayout = createAction(SET_WIDGETS_LAYOUT);
export const getWidgetsData = createAction(GET_WIDGETS_DATA);
export const getWidgetsDataSuccess = createAction(GET_WIDGETS_DATA_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: OverviewState = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_WIDGETS_LAYOUT]: setState('layout'),
    [GET_WIDGETS_DATA_SUCCESS]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};

// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

type OneDriveDailyStorageType = {
  date: string,
  users: [],
  isLoading: boolean,
  isErrored: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: OneDriveDailyStorageType = {
  date: '',
  users: [],
  isLoading: false,
  isErrored: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('modules/oneDriveDailyStorage');
const GET_ONEDRIVE_DAILY_STORAGE = wrapWithNamespace('GET_ONEDRIVE_DAILY_STORAGE');
const GET_ONEDRIVE_DAILY_STORAGE_SUCCESS = wrapWithNamespace('GET_ONEDRIVE_DAILY_STORAGE_SUCCESS');
const TOGGLE_LOADING_STATE = wrapWithNamespace('TOGGLE_LOADING_STATE');
const TOGGLE_ERROR_STATE = wrapWithNamespace('TOGGLE_ERROR_STATE');

export const getOneDriveDailyStorage = createAction(GET_ONEDRIVE_DAILY_STORAGE);
export const getOneDriveDailyStorageSuccess = createAction(GET_ONEDRIVE_DAILY_STORAGE_SUCCESS);
export const toggleLoadingState = createAction(TOGGLE_LOADING_STATE);
export const toggleErrorState = createAction(TOGGLE_ERROR_STATE);

/***************
 *   REDUCER   *
 ***************/

export default (state: OneDriveDailyStorageType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_ONEDRIVE_DAILY_STORAGE_SUCCESS]: mergeState,
    [TOGGLE_ERROR_STATE]: mergeState,
    [TOGGLE_LOADING_STATE]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};

// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

type LandingState = {
  signinUrl: string,
  isLoaded: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: LandingState = {
  signinUrl: '',
  isLoaded: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('pages/landing');
const GET_SIGNIN_URL = wrapWithNamespace('GET_SIGNIN_URL');
const GET_SIGNIN_URL_SUCCESS = wrapWithNamespace('GET_SIGNIN_URL_SUCCESS');

export const getSigninUrl = createAction(GET_SIGNIN_URL);
export const getSigninUrlSuccess = createAction(GET_SIGNIN_URL_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: LandingState = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_SIGNIN_URL_SUCCESS]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};

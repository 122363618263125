// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

export type UsersState = {
  users: [],
  isLoading: boolean,
  isLoaded: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: UsersState = {
  users: [],
  isLoading: false,
  isLoaded: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('pages/users');
const GET_USERS_DATA = wrapWithNamespace('GET_USERS_DATA');
const GET_USERS_DATA_SUCCESS = wrapWithNamespace('GET_USERS_SUCCESS');
const TOGGLE_LOADING_STATE = wrapWithNamespace('TOGGLE_LOADING_STATE');

export const getUsersData = createAction(GET_USERS_DATA);
export const getUsersDataSuccess = createAction(GET_USERS_DATA_SUCCESS);
export const toggleLoadingState = createAction(TOGGLE_LOADING_STATE);

/***************
 *   REDUCER   *
 ***************/

export default (state: UsersState = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_USERS_DATA_SUCCESS]: mergeState,
    [TOGGLE_LOADING_STATE]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};

// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

type MsAuthType = {
  msAuthState: string,
  msIsLoading: boolean,
  msLastProcessed: string,
  isLoaded: boolean,
  totalUsers: number,
  isLargeCustomer: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: MsAuthType = {
  msAuthState: '',
  msIsLoading: true,
  msLastProcessed: '',
  isLoaded: false,
  totalUsers: 0,
  isLargeCustomer: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('modules/auth');
const GET_MSAUTH_STATE = wrapWithNamespace('GET_MSAUTH_STATE');
const GET_MSAUTH_STATE_SUCCESS = wrapWithNamespace('GET_MSAUTH_STATE_SUCCESS');

export const getMsAuthState = createAction(GET_MSAUTH_STATE);
export const getMsAuthStateSuccess = createAction(GET_MSAUTH_STATE_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: MsAuthType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_MSAUTH_STATE_SUCCESS]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};

// @flow

import * as R from 'ramda';
import { command } from '@accordo-feed/micro-frontends';
import { core, url } from '@accordo-feed/micro-frontends-utils';

export const DEFAULT_LANGUAGE = 'en-us';
export const DEFAULT_COUNTRY_CODE = 'us';
export const LANGUAGE_CHOICE = 'language_choice';
export const SUBMODULE_NAME = 'consumption';
export const REDUCER_NAMESPACES = {
  SHELL: 'shell',
  OFFICE_365_OPTIMIZER: `sub_${core.snakeToCamel(SUBMODULE_NAME)}`
};

export const USERS_SECTIONS = {
  USERS: '',
  HIGH_COST_USERS: 'high-cost',
  DEPARTMENT_SPEND: 'department-spend',
  ONEDRIVE_TOP_USERS: 'onedrive-top-users',
  COMPANY_ONEDRIVE_STORAGE: 'company-onedrive-storage'
};

export const RECOM_SECTIONS = {
  ALL: 'all',
  SAVINGS: 'savings',
  STORAGE: 'storage',
  OPTIMIZE: 'optimize',
  SECURITY: 'security'
};

const orgId = url.getAcoOrgIdFromPathname();
const getOrgIdPath = path => `/${SUBMODULE_NAME}/${orgId}${path}`;
const getOrgIdPaths = (paths: Object): Object => R.map(getOrgIdPath, paths);

const PATH_DEFAULT = {
  HOME: '/',

  // Users page routes
  USERS: '/users',
  HIGH_COST_USERS: `/users/${USERS_SECTIONS.HIGH_COST_USERS}`,
  DEPARTMENT_SPEND: `/users/${USERS_SECTIONS.DEPARTMENT_SPEND}`,
  ONEDRIVE_TOP_USERS: `/users/${USERS_SECTIONS.ONEDRIVE_TOP_USERS}`,
  COMPANY_ONEDRIVE_STORAGE: `/users/${USERS_SECTIONS.COMPANY_ONEDRIVE_STORAGE}/:date`,

  // Plans page routes
  PLANS: '/plans',

  // Recommendations page routes
  RECOMMENDATIONS: '/recommendations',
  RECOMMENDATIONS_SAVINGS: `/recommendations/${RECOM_SECTIONS.SAVINGS}`,
  RECOMMENDATIONS_STORAGE: `/recommendations/${RECOM_SECTIONS.STORAGE}`,
  RECOMMENDATIONS_OPTIMIZE: `/recommendations/${RECOM_SECTIONS.OPTIMIZE}`,
  RECOMMENDATIONS_SECURITY: `/recommendations/${RECOM_SECTIONS.SECURITY}`,

  // TODO: Should go to Ipifny's 404 page by using command.navigateTo(PAGE_NOT_FOUND)
  PAGE_NOT_FOUND: '/errors/404'
};

export const PATHS = orgId ? getOrgIdPaths(PATH_DEFAULT) : PATH_DEFAULT;

export const API_KEYS = {
  MS_AUTH_STATE_API: 'MS_AUTH_STATE_API',
  MS_AUTH_SIGNIN_API: 'MS_AUTH_SIGNIN_API',
  USERS_API: 'USERS_API',
  ORGNIZATION_API: 'ORGNIZATION_API',
  USERS_LIST_API: 'USERS_LIST_API',
  OVERVIEW_WIDGETS_API: 'OVERVIEW_WIDGETS_API',
  ONEDRIVE_DAILY_REPORT_API: 'ONEDRIVE_DAILY_REPORT_API',
  MODERNIZATION_REPORT_API: 'MODERNIZATION_REPORT_API',
  RECOMMENDATIONS_API: 'RECOMMENDATIONS_API',
  REFRESH_API: 'REFRESH_API',
  DOWNLOAD_REPORT_API: 'DOWNLOAD_REPORT_API'
};

export const ASSESSMENT_PROXYS = {
  local: 'https://dev-assessment-proxy.assist.ms',
  dev: 'https://dev-assessment-proxy.assist.ms',
  uat: 'https://uat-assessment-proxy.assist.ms',
  prod: 'https://prod-assessment-proxy.assist.ms'
};

const CONSUMPTION_API_BASE_PATH = '/consumption/organizations/{{ orgId }}/office365/';

export const API_PATHS = {
  MODERNIZATION_REPORT_API: '/reporting-services/organizations/{{ orgId }}/modernization/?type=pdf&lang={{ lang }}',
  MS_AUTH_SIGNIN_API: `${CONSUMPTION_API_BASE_PATH}login`,
  MS_AUTH_STATE_API: `${CONSUMPTION_API_BASE_PATH}state`,
  ONEDRIVE_DAILY_REPORT_API: `${CONSUMPTION_API_BASE_PATH}onedriveusersdailyreport?date={{ date }}`,
  ORGNIZATION_API: '/organizations/{{ orgId }}',
  OVERVIEW_WIDGETS_API: `${CONSUMPTION_API_BASE_PATH}dashboard`,
  RECOMMENDATIONS_API: `${CONSUMPTION_API_BASE_PATH}recommendations`,
  USERS_API: `${CONSUMPTION_API_BASE_PATH}users`,
  USERS_LIST_API: `${CONSUMPTION_API_BASE_PATH}users`,
  REFRESH_API: `${CONSUMPTION_API_BASE_PATH}refresh`,
  DOWNLOAD_REPORT_API: '/reporting-services/msp/{{ orgId }}/download-report'
};

export const TIME_FORMATS = {
  TILL_NOW: 'TILL_NOW',
  SHORT: 'DD MMM YYYY',
  ISO_DATE: 'YYYY-MM-DD',
  CHART_DATE: 'DD/MMM'
};

export const AUTH_STATUS = {
  NEW: 'new',
  ACCESS_GRANTED: 'accessGranted',
  ACCESS_DENIED: 'accessDenied',
  AUTH_CANCELLED: 'authCancelled',
  INVALID_TOKEN: 'invalidToken'
};

export const DEFAULT_CURRENCY_OPTIONS = {
  symbol: '$',
  decimal: '.',
  separator: ',',
  precision: 2
};

export const SORTING = {
  ASC: 'asc',
  DESC: 'desc'
};

export const HOME_LIST = 'HOME_LIST';
export const HIGH_COST_LIST = 'HIGH_COST_LIST';
export const DEPT_SPEND_LIST = 'DEPT_SPEND_LIST';
export const ONEDRIVE_STORAGE_LIST = 'ONEDRIVE_STORAGE_LIST';
export const COMPANY_ONEDRIVE_STORAGE_LIST = 'COMPANY_ONEDRIVE_STORAGE_LIST';
export const SEARCH_TERM = 'SEARCH_TERM';

export const CONSUMPTION_REFRESH_STATUS = {
  SUCCESS: 'complete',
  FAIL: 'error'
};

// NOTE: Make sure the event keys are exactly the same as they are in the shell:
// https://bitbucket.org/accordogroup/csa.frontend/src/master/src/common/constants.js
export const MF_COMMAND_EVENTS = {
  ...command.EVENTS,
  CONSUMPTION_REFRESH_IOT: 'CONSUMPTION_REFRESH_IOT'
};

export const CONSUMPTION_TRACK_EVENTS = {
  LANDING_PAGE_LOAD_EVENT: 'signup_consumption_office365_landing_page',
  OFFICE365OPTIMIZER_OVERVIEW_PAGE_LOAD: 'office365optimizer_overview_page_load',
  SIGNUP_CONSUMPTION_OFFICE365_SIGNIN_CLICKED: 'signup_consumption_office365_signin_clicked',
  OFFICE365OPTIMIZER_POTENTIAL_ANNUAL_SAVINGS_VIEW: 'office365optimizer_potential_annual_savings_view',
  OFFICE365OPTIMIZER_HIGH_COST_USERS_YEAR_VIEWMORE: 'office365optimizer_high_cost_users_year_viewmore',
  OFFICE365OPTIMIZER_SOFTWARE_ASSETS_MODERNREPORT: 'office365optimizer_software_assets_modernreport',
  OFFICE365OPTIMIZER_DEPARTMENT_SPEND_VIEWMORE: 'office365optimizer_department_spend_viewmore',
  OFFICE365OPTIMIZER_SHAREPOINT_TOP_USERS_VIEWMORE: 'office365optimizer_sharepoint_top_users_viewmore',
  OFFICE365OPTIMIZER_USERS_VIEWMORE: 'office365optimizer_users_viewmore',
  OFFICE365OPTIMIZER_OVERVIEW_WIDGET_RECOMMENDATIONS_GETINSIGHTS:
    'office365optimizer_overview_widget_recommendations_getinsights',
  OFFICE365OPTIMIZER_COMPANY_ONEDRIVE_STORAGE_VIEWALLUSERS: 'office365optimizer_company_onedrive_storage_viewallusers',
  OFFICE365OPTIMIZER_RECOMMENDATIONS_TAB_ALL: 'office365optimizer_recommendations_tab_all',
  OFFICE365OPTIMIZER_RECOMMENDATIONS_TAB_SAVINGS: 'office365optimizer_recommendations_tab_savings',
  OFFICE365OPTIMIZER_RECOMMENDATIONS_TAB_ONEDRIVE_STORAGE: 'office365optimizer_recommendations_tab_onedrive_storage',
  OFFICE365OPTIMIZER_RECOMMENDATIONS_TAB_OPTIMIZE: 'office365optimizer_recommendations_tab_optimize',
  OFFICE365OPTIMIZER_RECOMMENDATIONS_TAB_SECURITY: 'office365optimizer_recommendations_tab_security',
  OFFICE365OPTIMIZER_RECOMMENDATIONS_SAVINGS_RECOMMENDATIONS_CHEVRON:
    'office365optimizer_recommendations_savings_recommendations_chevron',
  OFFICE365OPTIMIZER_RECOMMENDATIONS_STORAGE_RECOMMENDATIONS_CHEVRON:
    'office365optimizer_recommendations_storage_recommendations_chevron'
};

export const STORAGE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
export const BYTE_CONVERT_RATE = {
  B: 1,
  KB: Math.pow(1024, 1),
  MB: Math.pow(1024, 2),
  GB: Math.pow(1024, 3),
  TB: Math.pow(1024, 4),
  PB: Math.pow(1024, 5)
};

export const EMPTY_DATE_PLACEHOLDER = '-';
export const RECOMMENDATIONS_ACTION_TYPES = {
  SWITCH_PLAN: 'switch_plan',
  STOP_RENEWING: 'stop_renewing',
  REASSIGNABLE: 'reassignable',
  CONSULT_TEAMS: 'consult_teams',
  ONEDRIVE_DATA_FLUCTUATION: 'onedrive_data_fluctuation',
  ONEDRIVE_FILE_FLUCTUATION: 'onedrive_file_fluctuation',
  OPTIMIZE_TEAMS: 'optimize_teams',
  OPTIMIZE_CLOUD: 'optimize_cloud',
  MFA_REGISTRATION: 'mfa_registration',
  MFA_CAPABLE: 'mfa_capable',
  SSPR_ENABLED: 'sspr_enabled',
  SSPR_REGISTRATION: 'sspr_registration',
  MISASSIGNED_OFFBOARDED: 'misassigned_offboarded'
};

export const USER_ROLES = {
  USER: 'user',
  CONSULTANT: 'consultant',
  ANALYST: 'analyst',
  PARTNER: 'partner'
};

export const LOCAL_STORAGE_KEYS = {
  OPTIMIZER_WIDGETS_LAYOUT: 'optimizerWidgetsLayout'
};

export const SHELL_IDS = {
  CLOUD_OPTIMIZER: 'cloud-optimizer',
  IPIFNY: 'ipifny'
};

export const IMPACT_LEVELS = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low'
};

export const APP_FEATURES = {
  CSV_EXPORT: 'CsvExport',
  DEMO: 'Demo'
};

export const EXPORT_CSV_TYPES = {
  PLANS: 'plans',
  USERS: 'users',
  HIGHEST_COST_USERS: 'highestCostUsers',
  DEPARTMENT_SPEND: 'departmentSpend',
  COMPANY_STORAGE: 'companyStorage'
};

export const USERS_LICENSES_PARTNER_CENTER = {
  altText: 'manage in Partner Center',
  image: 'https://partner.microsoft.com/favicon.ico',
  url: 'https://partner.microsoft.com/en-us/dashboard/commerce2/customers/{{ tenantId }}/users/{{ userId }}/editdetails'
};

export const USERS_LICENSES_AZURE_ACTIVE = {
  altText: 'manage in Azure Active Directory',
  image: 'https://portal.azure.com/Content/favicon.ico',
  url: 'https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Licenses/userId/{{ userId }}'
};

export const PARTNER_CENTER_SUBSCRIPTIONS =
  'https://partner.microsoft.com/commerce/customers/{{ tenantId }}/subscriptions';

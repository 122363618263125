// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

export type OrgnizationType = ?{
  agreements: Array<Object>,
  assessmentId: string,
  countryCode: string,
  customerName: string,
  email: string,
  events: Object,
  position: Object,
  tpid: string,
  versionMarker: string,
  orgIsLoading: boolean
};

type StateType = {
  data: OrgnizationType,
  isLoaded: boolean,
  isLoading: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  isLoading: false,
  isLoaded: false,
  data: {}
};

/***************
 *   ACTIONS   *
 ***************/
const wrapWithNamespace = wrapWithConsumption('modules/orgnization');
const GET_ORGANIZATION = wrapWithNamespace('GET_ORGANIZATION');
const GET_ORGANIZATION_SUCCESS = wrapWithNamespace('GET_ORGANIZATION_SUCCESS');
const SET_LOADING_STATE = wrapWithNamespace('SET_LOADING_STATE');

export const getOrganization = createAction(GET_ORGANIZATION);
export const getOrganizationSuccess = createAction(GET_ORGANIZATION_SUCCESS);
export const setLoadingState = createAction(SET_LOADING_STATE);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_ORGANIZATION_SUCCESS]: (state, payload) => ({
      data: payload,
      isLoaded: true,
      isLoading: false
    }),
    [SET_LOADING_STATE]: setState('isLoading')
  }[type];

  return reducer ? reducer(state, payload) : state;
};

// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

export type RecommendationsType = {
  id: ?string,
  currency: ?string,
  savings: ?(Object[]),
  storage: ?(Object[]),
  optimize: ?(Object[])
};

type StateType = {
  data: RecommendationsType,
  isLoaded: boolean,
  isLoading: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  data: {},
  isLoaded: false,
  isLoading: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('pages/recommendations');
const GET_RECOMMENDATIONS = wrapWithNamespace('GET_RECOMMENDATIONS');
const GET_RECOMMENDATIONS_SUCCESS = wrapWithNamespace('GET_RECOMMENDATIONS_SUCCESS');
const SET_LOADING_STATE = wrapWithNamespace('SET_LOADING_STATE');

export const getRecommendations = createAction(GET_RECOMMENDATIONS);
export const getRecommendationsSuccess = createAction(GET_RECOMMENDATIONS_SUCCESS);
export const setLoadingState = createAction(SET_LOADING_STATE);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_RECOMMENDATIONS_SUCCESS]: (state, payload) => ({
      data: payload,
      isLoaded: true,
      isLoading: false
    }),
    [SET_LOADING_STATE]: setState('isLoading')
  }[type];

  return reducer ? reducer(state, payload) : state;
};

// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithConsumption } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  isLoading: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  isLoading: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithConsumption('modules/report');
const TRIGGER_REPORT_DOWNLOAD = wrapWithNamespace('TRIGGER_REPORT_DOWNLOAD');
const SET_REPORT_ISLOADING = wrapWithNamespace('SET_REPORT_ISLOADING');

export const triggerReportDownload = createAction(TRIGGER_REPORT_DOWNLOAD);
export const setReportIsLoading = createAction(SET_REPORT_ISLOADING);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_REPORT_ISLOADING]: setState('isLoading')
  }[type];

  return reducer ? reducer(state, payload) : state;
};

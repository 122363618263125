// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';
import { redux } from '@accordo-feed/micro-frontends';

import { SUBMODULE_NAME, REDUCER_NAMESPACES } from 'src/constants';

export const wrapWithConsumption = R.curry(redux.getActionWrapper)(SUBMODULE_NAME);

export const getModuleSelector = (key: string) =>
  createSelector(
    state => state[REDUCER_NAMESPACES.OFFICE_365_OPTIMIZER][key],
    module => module
  );

export const getShellModuleSelector = (key: string, namespace: string = REDUCER_NAMESPACES.SHELL) =>
  createSelector(
    state => state[namespace][key],
    module => module
  );
